<template>
    <div>
        <el-dialog title="设置别名" :visible="setNameShow" width="480px" @opened="openInit" @close="cancel">
            <el-form :model="form"  label-width="80px" :rules="rules" ref="displayNameForm">
                <el-form-item label="名称：" prop="name">
                    <el-input v-model="form.name" autocomplete="off" placeholder="请输入名称"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="sure">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {actNameSet} from '@/api/adManagement'
import { mapState } from 'vuex';
export default {
    props:{
        setNameShow:{
            type: Boolean,
            default: false,
        },
        selectedRow:{
            type:Object,
            default:()=>{}
        }
    },
    data() {
        return {
            form:{
                name:''
            },
            rules:{
                name:[{required:true,message:'请填写名称',trigger:['change','blur']},{ min: 1, max: 50, message: '长度再50字符以内哦', trigger: 'blur' }]
            }
        }
    },
    computed:{
        ...mapState('num', ['activeAdAccount']),
    },
    methods:{
        cancel(){
            this.$emit('update:setNameShow',false)
        },
        openInit(){
            this.form.name = this.selectedRow.displayName || this.selectedRow.name
        },
        sure(){
            this.$refs.displayNameForm.validate(valid=>{
                if(valid) {
                    let params = {
                        id:this.selectedRow.id,
                        displayName:this.form.name.trim()
                    }
                    this.$showLoading()
                    actNameSet(JSON.stringify(params)).then(res=>{
                        this.$hideLoading()
                        if(res.code == 0){
                            this.$emit('setName',params);
                            let list = this.$store.state.num.adAccountsLists;
                            let index = list.findIndex(v=>v.id == this.selectedRow.id);
                            this.$set(list,index,{...list[index],displayName:this.form.name})
                            this.$store.dispatch('num/setAdAccountsLists', {
                                adAccountsLists:list,
                            });
                            if(this.selectedRow.id == this.activeAdAccount.id){
                                this.$store.dispatch('num/setActiveAdAccount', {
                                    activeAdAccount:{...activeAdAccount,displayName:this.form.name},
                                });
                            }
                            this.cancel()
                        }
                    })
                }
            })
        }
    }
}
</script>
<style lang="scss"></style>